import React from "react";
import Head from "next/head";
import "../styles/globals.css";
import type { AppProps } from "next/app";
import { init } from "@amplitude/analytics-browser";
import { ThemeProvider } from "@mui/material/styles";
import muiTheme from "@config/muiTheme";
import Header from "@components/Header";
import Box from "@mui/material/Box";
import { useRouter } from "next/router";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  ApolloLink,
  HttpLink,
} from "@apollo/client";
import EnvironmentContext from "@contexts/EnvironmentContext";
import { Environment } from "@/types";

if (process.env.NEXT_PUBLIC_API_MOCKING === "enabled") {
  require("../mocks");
}

const stagingEndpoint = new HttpLink({
  uri: "https://api.stg.cybertino.io/connect/",
});

const productionEndpoint = new HttpLink({
  uri: "https://api.cybertino.io/connect/",
});

const client = new ApolloClient({
  link: ApolloLink.split(
    (operation) => operation.getContext().environment === "Staging",
    stagingEndpoint, //if above
    productionEndpoint
  ),
  cache: new InMemoryCache(),
});

init((process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY as string) || "", undefined, {
  optOut: process.env.NEXT_PUBLIC_VERCEL_ENV !== "production",
});

function App({ Component, pageProps }: AppProps) {
  const [environment, setEnvironment] =
    React.useState<Environment>("Production");
  const router = useRouter();
  const paths = ["/", "/integration"];

  return (
    <div>
      <Head>
        <title>CyberConnect Explorer</title>
        <link rel="CyberConnect Explorer icon" href="/favicon.ico" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <meta property="og:title" content="CyberConnect Explorer" />
        <meta
          property="og:description"
          content="Explorer CyberConnect Social Graph"
        />
      </Head>
      <EnvironmentContext.Provider value={environment}>
        <ApolloProvider client={client}>
          <ThemeProvider theme={muiTheme}>
            <Box sx={{ px: { xs: 2.5, md: 6 }, pt: { xs: 2.5, md: 3 } }}>
              <Header
                activeTab={paths.indexOf(router.pathname)}
                changeEnvironment={(value: Environment) =>
                  void setEnvironment(value)
                }
              />
            </Box>
            <Component {...pageProps} />
          </ThemeProvider>
        </ApolloProvider>
      </EnvironmentContext.Provider>
    </div>
  );
}

export default App;
