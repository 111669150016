import Box from "@mui/material/Box";
import Image from "next/image";
import type { IconName } from "@/types";

const sizes = {
  small: {
    box: 44,
    icon: 18,
    radius: "8px",
  },
  middle: {
    box: 56,
    icon: 18,
    radius: "8px",
  },
  large: {
    box: 88,
    icon: 32,
    radius: "24px",
  },
};

export interface IconBaseProps {
  name: IconName;
  size?: keyof typeof sizes;
}

export type IconProps =
  | (IconBaseProps & {
      variant?: "icon";
      rounded: never;
    })
  | (IconBaseProps & {
      variant?: "contained";
      rounded?: boolean;
    });

const getIconPath = (name: string) => "/assets/icons/" + name + ".svg";

const IconBase = ({ name, size = "small", ...rest }: IconBaseProps) => {
  return (
    <Image
      src={getIconPath(name)}
      width={sizes[size].icon}
      height={sizes[size].icon}
      alt={name + " icon"}
      {...rest}
    />
  );
};

const Icon = ({
  name,
  size = "small",
  variant = "icon",
  rounded,
}: IconProps) => {
  if (variant === "contained") {
    return (
      <Box
        data-testid={`icon-${name}-${size}-${variant}${
          rounded ? "-rounded" : ""
        }`}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: sizes[size].box,
          height: sizes[size].box,
          backgroundColor: "#fff",
          border: "1.12943px solid #F0F1F5;",
          borderRadius: rounded ? sizes[size].radius : 100,
          boxSizing: "border-box",
        }}
      >
        <IconBase name={name} size={size} />
      </Box>
    );
  }

  return (
    <IconBase data-testid={`icon-${name}-${size}`} name={name} size={size} />
  );
};

export default Icon;
