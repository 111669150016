import { createTheme, ThemeOptions } from "@mui/material/styles";

let theme = createTheme({
  spacing: 8,
});

const typography: ThemeOptions["typography"] = {
  fontFamily: ["Manrope"].join(","),
  h1: {
    fontSize: "28px",
    lineHeight: "40px",
    fontWeight: 700,
  },
};

const palette: ThemeOptions["palette"] = {
  primary: {
    main: "#2D4DF5",
  },
  text: {
    primary: "#222223",
    secondary: "#4E4E4F",
    disabled: "rgba(34, 34, 35, 0.6)",
  },
};

theme = createTheme(theme, { palette });

const components: ThemeOptions["components"] = {
  MuiButton: {
    styleOverrides: {
      root: {
        boxShadow: "none",
        borderRadius: 8,
        fontSize: "1rem",
        padding: "20px 24px",
        height: 64,
        textTransform: "unset",
        "&:hover": {
          backgroundColor: "#fff",
          boxShadow: "none",
        },
      },
      contained: {
        color: "#fff",
        backgroundColor: theme.palette.primary.main,
        "&:hover": {
          color: "#fff",
          backgroundColor: theme.palette.primary.main,
        },
      },
    },
  },

  MuiButtonBase: {
    defaultProps: {
      disableRipple: false,
    },
    styleOverrides: {
      root: {
        color: "#000",
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      root: {
        borderRadius: 28,
      },
      sizeSmall: {
        height: 44,
        padding: `${theme.spacing(1.5)} ${theme.spacing(3)}`,
      },
      sizeMedium: {
        height: 56,
        padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
        fontSize: 16,
        color: palette.text?.secondary,
      },
      filled: {
        backgroundColor: "#F7F8F9",
        fontWeight: 600,
      },
      outlined: {
        color: palette.text?.disabled,
        border: " 1.12943px solid #EAEBEF",
      },
    },
  },
};

theme = createTheme(theme, { components });

const muiTheme = createTheme({ ...theme, typography });

export default muiTheme;
