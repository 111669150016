import { graphql } from "msw";
import { faker } from "@faker-js/faker";
import { mockConnectionSummaryQuery } from "@/graphql";

const connectionSummaryQueryHandler = mockConnectionSummaryQuery(
  (req, res, ctx) => {
    return res(
      ctx.delay(3000),
      ctx.data({
        connectionSummary: {
          userCount: faker.datatype.number(),
          userDelta: faker.datatype.number(),
          connectionCount: faker.datatype.number(),
          connectionDelta: faker.datatype.number(),
          namespaceCount: faker.datatype.number(),
        },
      })
    );
  }
);

export const handlers = [];
