import React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Navigation from "@components/Navigation";
import Button from "@mui/material/Button";
import Image from "next/image";
import Icon from "@components/Icon";
import Link from "next/link";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Typography from "@mui/material/Typography";
import { Environment } from "@/types";

const navList = [
  { name: "Home", path: "/" },
  {
    name: "Integration",
    path: "/integration",
  },
];

export type HeaderProps = {
  activeTab?: number;
  changeEnvironment: (value: Environment) => void;
};

const externalLinks = [
  { name: "CyberConnect", url: "https://cyberconnect.me" },
];

const LinkGroup = () => {
  return (
    <Stack>
      {externalLinks.map(({ name, url }) => (
        <Link href={url} key={name} passHref>
          <a target="_blank" rel="noopener noreferrer">
            {name}
          </a>
        </Link>
      ))}
    </Stack>
  );
};

type EnvironmentSelectorProps = {
  handleSelect: (value: Environment) => void;
};

const EnvironmentSelector = ({ handleSelect }: EnvironmentSelectorProps) => {
  const options = ["Production", "Staging"] as const;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const open = Boolean(anchorEl);

  const handleButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLElement>,
    index: number
  ) => {
    if (index === selectedIndex) return;

    setSelectedIndex(index);
    handleSelect(options[index]);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const dotColors = ["#05B636", "#FFB800"];

  return (
    <Box>
      <Button
        variant="outlined"
        sx={{
          border: "1px solid #D7DDE0",
          borderRadius: "24px",
          height: 40,
          color: "text.primary",
          width: 160,
        }}
        onClick={handleButtonClick}
        startIcon={
          <Box
            sx={{
              border: `3px solid ${dotColors[selectedIndex]}`,
              borderRadius: "100px",
            }}
          />
        }
        endIcon={<Icon name="arrow-down" />}
      >
        {options[selectedIndex]}
      </Button>
      <Menu
        id="env-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          ".MuiMenu-paper": {
            boxShadow: "0px 2px 8px rgba(34, 34, 35, 0.08)",
            borderRadius: "16px",
            border: "1px solid #D7DDE0",
            mt: 0.5,
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem disabled sx={{ "&.Mui-disabled": { opacity: 1 } }}>
          <Typography fontSize={15} color="#646975">
            Environments
          </Typography>
        </MenuItem>
        {options.map((option, index) => (
          <MenuItem
            key={option}
            selected={index === selectedIndex}
            onClick={(event) => handleMenuItemClick(event, index)}
            sx={{
              width: 188,
            }}
          >
            <Box
              sx={{
                border: `3px solid ${dotColors[index]}`,
                borderRadius: "100px",
                mr: 1.75,
              }}
            />
            {option}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

const Header = ({ activeTab, changeEnvironment }: HeaderProps) => {
  const [tabValue, setTabValue] = React.useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    if (newValue === tabValue) return;

    setTabValue(newValue);
  };

  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Link href="/">
        <Box
          sx={{
            cursor: "pointer",
            width: 200,
            height: 100,
            position: "relative",
          }}
        >
          <Image
            src="/logo.png"
            layout="fill"
            objectFit="contain"
            alt="CyberConnect Explorer Logo"
          />
        </Box>
      </Link>
    </Stack>
  );
};

export default Header;
